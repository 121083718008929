import { Viewer } from '@toast-ui/react-editor';
import { ShiftType } from '@tymbe/schema/enums';
import { PaySupplementRules } from '@tymbe/schema/pay-supplement.interface';
import { Card } from 'antd';
import { Form, FormApi, FormState } from 'informed';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import DeleteOrder from './components/DeleteOrder';
import feathersClient from '../../../apiClient';
import { useUser } from '../../../apiClient/ApiContext';
import Protect from '../../../apiClient/Protect';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import { SubmitButton } from '../../../components/buttons';
import { getValuesForPatch } from '../../../components/forms/form-util';
import { ClockIcon } from '../../../components/icons';
import TyDateInput from '../../../components/inputs/TyDateInput';
import TyInput from '../../../components/inputs/TyInput';
import TyMarkdownEditor from '../../../components/inputs/TyMarkdownEditor';
import TySelectPosition from '../../../components/inputs/TySelectPosition';
import TySelectRequirements from '../../../components/inputs/TySelectRequirements';
import TySelectUtility from '../../../components/inputs/TySelectUtility/TySelectUtility';
import PaySupplement from '../../../components/PaySupplement/PaySupplement';
import { PageTitle } from '../../../components/texts';
import Wrapper from '../../../components/wrapper';
import Container from '../../../containers';
import { RequestBody } from '../../../types/ReactQuery';
import {
  BranchofficeData,
  DocumentTypeData,
  PerkData,
  ShiftData,
  isPerkData,
  isDocumentTypeData,
} from '../../../types/TymbeApi';
import { joinDateTime } from '../../../utils';
import { Roles } from '../../../utils/enums';
import Spinner from '../../../utils/spinner';
import { isBetween } from '../../../utils/validations';
import { ShiftFormValues, validateContactInfo } from '../create/components/ShiftForm';

const ShiftDetail = () => {
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string | null>(null);
  const { shiftId } = useParams();
  const user = useUser();
  const queryClient = useQueryClient();
  const history = useNavigate();
  const formApiRef = useRef<FormApi<Partial<ShiftFormValues>
  & {
    contest_start_date: string,
    contest_end_date: string,
    contest_start_time: string,
    contest_end_time: string
  }>>();

  const { data: shiftInfo, isLoading } = useQuery(['shift', shiftId], async (): Promise<ShiftData> =>
    feathersClient.service('shift').get(shiftId!, {
      query: {
        $eager: '[shiftTemplate(withDeleted), branchoffice, perk, documentType, branchoffice.parent, manShift, position, utility, company.defaultSettings]',
      },
    }), { enabled: !!shiftId, refetchOnWindowFocus: false });

  const { mutateAsync: patchOrder } = useMutation(
    ['shift', shiftId],
    (req: RequestBody<Partial<ShiftData>>): Promise<ShiftData> =>
      feathersClient.service('shift').patch(req.id, req.body),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['shift', shiftId]);
      },
    },
  );

  const { mutateAsync: patchBranchoffice } = useMutation(
    ['branchoffice'],
    (req: RequestBody<Partial<BranchofficeData> | FormData>): Promise<BranchofficeData> =>
      feathersClient.service('branchoffice').patch(req.id, req.body),
    {
      onSuccess: () => {
        history('/shift');
      },
    },
  );

  if (!shiftId) {
    return <Navigate to="/shift" />;
  }

  const requirements = user.role.some((role) => [
    Roles.SUPER_ADMIN,
    Roles.ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.TYMBE_COORDINATOR,
  ].includes(role.slug))
    ? [...(shiftInfo?.perk || []), ...(shiftInfo?.documentType || [])]
    : [...(shiftInfo?.perk?.filter((perk) => perk.is_visible) || []), ...(shiftInfo?.documentType || [])];

  const onSubmit = async (
    formState:
    FormState<Partial<ShiftFormValues> & {
      contest_start_date: string,
      contest_end_date: string,
      contest_start_time: string,
      contest_end_time: string
    }>,
  ) => {
    const modifiedAndNulled = getValuesForPatch(formState);

    const { values, modified } = formState;
    if (!shiftId) {
      history('/shift');
      return;
    }
    setErrorText(null);

    const contest_start = joinDateTime(values.contest_start_date, values.contest_start_time);
    const contest_end = joinDateTime(values.contest_end_date, values.contest_end_time);

    if (contest_start.isAfter(values.start_time)
      || contest_start.isSameOrAfter(contest_end)
      || contest_end.isAfter(shiftInfo?.start_time)) {
      setErrorText('Neplatné časy začátku/konce soutěže');
      return;
    }

    let documentTypes: DocumentTypeData[] | undefined = [];
    let perks: PerkData[] | undefined = [];
    if (modifiedAndNulled.requirements?.length !== 0 || shiftInfo?.perk?.some((perk) => !perk.is_visible)) {
      const newDocTypes = modifiedAndNulled.requirements?.filter(isDocumentTypeData) || [];
      const newPerks = modifiedAndNulled.requirements?.filter(isPerkData) || [];

      documentTypes = [...modifiedAndNulled.shift_template?.documentType || [], ...newDocTypes];

      if (!user.role.some((role) => [
        Roles.SUPER_ADMIN,
        Roles.TYMBE_ADMIN,
        Roles.COMPANY,
      ].includes(role.slug))) {
        perks = [
          ...(shiftInfo?.perk?.filter((perk) => !perk.is_visible)) || [],
          ...modifiedAndNulled.shift_template?.perk || [], ...newPerks];
      } else {
        perks = [...modifiedAndNulled.shift_template?.perk || [], ...newPerks];
      }
    }
    if (perks.length === 0 && !modified.requirements) {
      perks = undefined;
    }
    if (documentTypes.length === 0 && !modified.requirements) {
      documentTypes = undefined;
    }

    let paySupplement: PaySupplementRules | undefined | null;
    if ('pay_supplement' in modifiedAndNulled) {
      paySupplement = values.pay_supplement;
    }
    if ('$paySupplement' in modifiedAndNulled && modifiedAndNulled.$paySupplement === false) {
      paySupplement = null;
    }

    const shiftData = {
      description: modifiedAndNulled.description,
      position_id: modifiedAndNulled.position?.id,
      perk: perks,
      pay_supplement: paySupplement,
      documentType: documentTypes,
      contact_person: modifiedAndNulled.contact_person,
      instruction: modifiedAndNulled.instruction,
      instruction_newcomers: modifiedAndNulled.instruction_newcomers,
      contest_start,
      contest_end,
      utility: modifiedAndNulled.utility,
      payment_base: Number(modifiedAndNulled.payment_base) || undefined,
      credits: Number(modifiedAndNulled.credits) || undefined,
      credits_min: Number(modifiedAndNulled.credits_min) || undefined,
    };
    const branchofficeData = {
      instruction: modifiedAndNulled.branchoffice?.parent?.instruction,
    };
    const departmentData = {
      instruction: modifiedAndNulled.branchoffice?.instruction,
    };

    try {
      setFormDisabled(true);
      // Patch shift data
      await patchOrder({ id: shiftId, body: shiftData });
      // patch branchoffice data
      if (shiftInfo?.branchoffice?.parent?.id) {
        await patchBranchoffice({ id: shiftInfo.branchoffice.parent.id, body: branchofficeData });
      } else {
        ErrorAlert('Při editaci údajů provozovny došlo k chybě');
      }
      // patch department data
      if (shiftInfo?.branchoffice?.id) {
        await patchBranchoffice({ id: shiftInfo.branchoffice.id, body: departmentData });
      } else {
        ErrorAlert('Při editaci údajů pobočky došlo k chybě');
      }
    } catch {
      ErrorAlert('Při editaci údajů došlo k chybě');
      setFormDisabled(false);
      return;
    }
    SuccessAlert('Objednávka úspěšně uložena');
    setFormDisabled(false);
  };

  const range = (start: number, end: number) => Array(end - start + 1).fill(undefined).map((_, idx) => start + idx);

  const disabledTime = (_currentDate: moment.Moment, type: 'start' | 'end') => {
    const { start_time } = shiftInfo as ShiftData;
    const contest_start_date = formApiRef.current?.getValue('contest_start_date') as string;
    const contest_start_time = formApiRef.current?.getValue('contest_start_time') as string;
    const contest_end_date = formApiRef.current?.getValue('contest_end_date') as string;
    const contest_end_time = formApiRef.current?.getValue('contest_end_time') as string;
    const isSameDayAsShiftStart = moment(start_time).isSame(contest_end_date, 'day');
    const isSameHourAsShiftStart = moment(start_time).isSame(contest_end_time, 'hour');

    if (moment(contest_start_date).isSame(contest_end_date, 'day')) {
      const areContestsTimesSameHour = moment(contest_start_time).hour() === moment(contest_end_time).hour();

      if (type === 'start') {
        // Disable hours before the start limit
        return {
          disabledHours: () =>
            range(moment(contest_end_time).hour() + 1, 24),
          disabledMinutes:
            () => (
              areContestsTimesSameHour
                ? range(moment(contest_end_time).minutes() + 1, 59)
                : []
            ),
        };
      }

      // Contest end time, disable any time before contest start time and any time after shift start time
      return {
        disabledHours: () =>
          range(0, moment(contest_start_time).hour() - 1)
            .concat(
              range(isSameDayAsShiftStart ? moment(start_time).hour() + 1 : 24, 24),
            ),
        disabledMinutes: () => (
          areContestsTimesSameHour
            ? range(0, moment(contest_start_time).minutes() - 1)
            : []
        )
          .concat(
            isSameDayAsShiftStart && isSameHourAsShiftStart
              ? range(moment(start_time).minutes() + 1, 59)
              : [],
          ),
      };
    }
    // If start and end datetimes are in different days
    if (type === 'start') {
      // allow all
      return {};
    }

    // If setting contest end. Check if shift start time is the same as the selected contest_end_time
    if (isSameDayAsShiftStart) {
      // Disable hours after the end limit and before the current day's limits
      return {
        disabledHours: () =>
          range(moment(start_time).hour() + 1, 24),
        disabledMinutes: () => (
          moment(start_time).isSame(contest_end_time, 'hour')
            ? range(moment(start_time).minutes() + 1, 60)
            : []
        ),
      };
    }

    return {};
  };
  const getDisabledStartDate = (date: moment.Moment) =>
    date.isAfter(
      moment(
        formApiRef.current?.getValue('contest_end_date') as string,
      )
        .add(1, 'day')
      || shiftInfo?.start_time,
    );

  const getDisabledContestEndDate = (date: moment.Moment) =>
    date.isBefore(
      moment(
        formApiRef.current?.getValue('contest_start_date') as string,
      ).subtract(1, 'day'),
    ) || date.isAfter(shiftInfo?.start_time);

  const isEditDisabled =
    (!user.hasRoles([Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN])
    && (moment(shiftInfo?.start_time).isBefore(moment())))
    || shiftInfo?.type === ShiftType.VACATION;

  const shiftTemplateName = shiftInfo?.shiftTemplate?.name || shiftInfo?.shiftTemplate?.template_name;

  const isCompanyUser = user.role.some((role) => [
    Roles.COMPANY,
    Roles.BRANCHOFFICE_MANAGER,
    Roles.BRANCHOFFICE_SUPERVISOR,
    Roles.SHIFT_SUPERVISOR,
  ].includes(role.slug));

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#ff0"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <div className="flex space-x-4 items-baseline justify-between">
          <PageTitle>
            {shiftTemplateName
              ? (
                <span>
                  Detail objednávky {moment(shiftInfo?.start_time).format('DD.MM.YYYY')} {' '}
                  ({shiftTemplateName} {moment(shiftInfo?.start_time).format('HH:mm')}
                  -{moment(shiftInfo?.end_time).format('HH:mm')})
                </span>
              ) : ''}
          </PageTitle>
          {!shiftInfo?.id ? null : (
            <Protect
              auth={[
                Roles.SUPER_ADMIN,
                Roles.TYMBE_ADMIN,
              ]}
              redirect={false}
            >
              <DeleteOrder
                shiftId={shiftInfo.id}
                isEmpty={!shiftInfo.manShift?.some((ms) => ms.application_id && ms.deleted_at === null)}
                disabled={
                  isLoading
                  || (moment(shiftInfo.start_time).isBefore(moment()))
                  || shiftInfo?.type === ShiftType.VACATION
                }
              />
            </Protect>
          )}
        </div>
        <Card>
          {shiftInfo && !isLoading
            ? (
              <Form
                formApiRef={formApiRef}
                onSubmit={onSubmit}
                initialValues={{
                  branchoffice: shiftInfo?.branchoffice,
                  payment_base: shiftInfo?.payment_base,
                  credits: shiftInfo?.credits,
                  invitation_credits: shiftInfo?.invitation_credits,
                  billing_rate: shiftInfo?.billing_rate,
                  margin: shiftInfo?.margin,
                  contact_person: shiftInfo.contact_person,
                  description: shiftInfo?.description,
                  requirements: requirements.length ? requirements : [],
                  contest_start_date: shiftInfo.contest_start,
                  contest_end_date: shiftInfo.contest_end,
                  contest_start_time: shiftInfo.contest_start,
                  contest_end_time: shiftInfo.contest_end,
                  job_classification_ids: shiftInfo.job_classification_ids,
                  position: shiftInfo.position,
                  expected_hpp_weekly_hours: shiftInfo.expected_hpp_weekly_hours,
                  maximum_hpp_trial_period: shiftInfo.maximum_hpp_trial_period,
                  utility: shiftInfo.utility,
                }}

              >
                <div className="flex lg:flex-row items-stretch flex-col gap-5">
                  <div className="flex flex-col gap-5 lg:min-w-[50%]">
                    <h3 className="ty-h3 text-align-start">
                      Základní údaje
                    </h3>
                    <TyInput
                      name="branchoffice.parent.name"
                      label="Provozovna"
                      readOnly
                    />
                    <TyInput
                      name="branchoffice.parent.display_name"
                      label="Provozovna - název pro brigádníky"
                      readOnly
                      disabled={!shiftInfo.branchoffice?.parent?.display_name}
                    />
                    <TyInput
                      name="branchoffice.name"
                      label="Oddělení"
                      readOnly
                    />
                    {!isEditDisabled ? (
                      <TyMarkdownEditor
                        name="description"
                        label="Popis pozice"
                        initialValue={shiftInfo?.description}
                      />
                    ) : (
                      <>
                        <h4>Popis pozice</h4>
                        <Viewer initialValue={shiftInfo?.description} />
                      </>
                    )}

                    <h4 className="ty-h4 mb-0 pb-0 text-align-start">
                      Začátek soutěže
                    </h4>
                    <div className="flex gap-2">
                      <TyDateInput
                        initialValue={moment(shiftInfo.contest_start)}
                        name="contest_start_date"
                        label="Datum začátku soutěže"
                        disabledDate={getDisabledStartDate}
                        disabled={isEditDisabled}
                      />
                      <TyDateInput
                        initialValue={moment(shiftInfo.contest_start)}
                        name="contest_start_time"
                        picker="time"
                        label="Čas začátku soutěže"
                        disabledTime={(date) => disabledTime(date, 'start')}
                        // limited by contest_end, no time after contest_end
                        minuteStep={15}
                        suffixIcon={<ClockIcon />}
                        disabled={isEditDisabled}
                      />
                    </div>
                    <h4 className="ty-h4 mb-0 pb-0 text-align-start">Konec soutěže</h4>
                    <div className="flex gap-2">
                      <TyDateInput
                        initialValue={moment(shiftInfo.contest_end)}
                        name="contest_end_date"
                        label="Datum konce soutěže"
                        disabledDate={getDisabledContestEndDate}
                        disabled={isEditDisabled}
                      />
                      <TyDateInput
                        initialValue={moment(shiftInfo.contest_end)}
                        name="contest_end_time"
                        picker="time"
                        label="Čas konce soutěže"
                        // no time before contest_start AND no time after start_time
                        disabledTime={(date) => disabledTime(date, 'end')}
                        minuteStep={15}
                        suffixIcon={<ClockIcon />}
                        disabled={isEditDisabled}
                      />
                    </div>

                    <TySelectRequirements
                      name="requirements"
                      label="Podmínky"
                      isMulti
                      isDisabled={isEditDisabled}
                      userRole={user.role}
                    />
                    <TySelectUtility
                      name="utility"
                      label="Pomůcky"
                      isMulti
                      isDisabled={isEditDisabled}
                    />
                    <Protect
                      auth={[
                        Roles.SUPER_ADMIN,
                      ]}
                      redirect={false}
                    >
                      <TySelectPosition name="position" label="Tymbe pozícia" />
                    </Protect>

                    <TyInput
                      initialValue={shiftInfo.contact_person}
                      name="contact_person"
                      label="Kontaktní osoba"
                      disabled={isEditDisabled}
                      validate={validateContactInfo}
                      validateOn="change"
                    />
                  </div>
                  <div className="flex flex-col gap-5 w-[100%]">
                    <h3 className="ty-h3 text-align-start">
                      Peníze
                    </h3>
                    <TyInput
                      name="payment_base"
                      label="Hodinová odměna"
                      type="number"
                      validate={(value: number) =>
                        (formApiRef.current?.getFormState().dirt.payment_base
                          ? isBetween(
                            shiftInfo?.company?.defaultSettings?.min_payment_base,
                            shiftInfo?.company?.defaultSettings?.max_payment_base,
                          )(value) : undefined)}
                    />

                    {!isCompanyUser
                      && (
                        <>
                          <TyInput
                            name="billing_rate"
                            label="Fakturační tarif"
                            readOnly
                          />
                          <TyInput
                            name="margin"
                            label="Marže"
                            readOnly
                          />
                          <h3
                            className="ty-h3 text-align-start"
                          >
                            Kreditové bonusy
                          </h3>
                          <TyInput
                            name="credits"
                            type="number"
                            label="Maximální kredity"
                            validate={(value: number) =>
                              (formApiRef.current?.getFormState().dirt.credits
                                ? isBetween(
                                  shiftInfo?.company?.defaultSettings?.min_credits,
                                  shiftInfo?.company?.defaultSettings?.max_credits,
                                )(value) : undefined)}
                          />
                          <TyInput
                            name="invitation_credits"
                            label="Kredity za pozvání"
                            type="number"
                            validate={(value: number) =>
                              (formApiRef.current?.getFormState().dirt.invitation_credits
                                ? isBetween(
                                  shiftInfo?.company?.defaultSettings?.min_credits,
                                  shiftInfo?.company?.defaultSettings?.max_credits,
                                )(value) : undefined)}
                          />
                        </>
                      )}
                  </div>
                </div>
                <h3 className="ty-h3 text-align-start">
                  Příplatky
                </h3>
                <PaySupplement
                  name="pay_supplement"
                  isDisabled={isEditDisabled}
                  initialValues={shiftInfo.pay_supplement}
                />
                {typeof shiftInfo.maximum_hpp_trial_period === 'number'
                && shiftInfo.expected_hpp_weekly_hours && (
                  <>
                    <h3>HPP</h3>
                    <div className="flex gap-2">
                      <TyInput
                        name="expected_hpp_weekly_hours"
                        label="Týdenní úvazek na HPP (v hodinách)"
                        readOnly
                      />

                      <TyInput
                        name="maximum_hpp_trial_period"
                        label="Maximální zkušební doba (ve dnech)"
                        readOnly
                      />
                    </div>
                  </>
                )}
                <div className="flex flex-col gap-5">
                  <h3 className="ty-h3 text-align-start">
                    Doplňující údaje
                  </h3>
                  {shiftInfo?.branchoffice?.parent?.instruction && (!isEditDisabled ? (
                    <TyMarkdownEditor
                      name="branchoffice.parent.instruction"
                      label="Pokyny provozovny"
                      initialValue={shiftInfo?.branchoffice?.parent?.instruction}
                    />
                  )
                    : (
                      <><h4>Pokyny provozovny</h4>
                        <Viewer initialValue={shiftInfo?.branchoffice?.parent?.instruction} />
                      </>
                    ))}
                  {shiftInfo?.branchoffice?.instruction && (!isEditDisabled ? (
                    <TyMarkdownEditor
                      name="branchoffice.instruction"
                      label="Pokyny pobočky"
                      initialValue={shiftInfo?.branchoffice?.instruction}
                    />
                  )
                    : (
                      <><h4>Pokyny pobočky</h4>
                        <Viewer initialValue={shiftInfo?.branchoffice?.instruction} />
                      </>
                    ))}
                </div>
                {!isEditDisabled
                && <SubmitButton disabled={formDisabled} style={{ marginTop: '18px' }} buttontext="Uložit" /> }
                {errorText && <span className="text-error ml-4 ">{errorText}</span>}
              </Form>
            )
            : <Spinner />}
        </Card>
      </Wrapper>
    </Container>
  );
};

export default ShiftDetail;
