import { FeathersError } from '@feathersjs/errors';
import { UtilityData } from '@tymbe/schema/utility.interface';
import { Card } from 'antd';
import { Form, FormApi, FormState } from 'informed';
import moment from 'moment';
import { useCallback, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import ShiftForm, { ShiftFormValues } from './components/ShiftForm';
import styles from './components/ShiftForm.module.css';
import ShiftRecapitulation from './components/ShiftRecapitulation';
import feathersClient from '../../../apiClient';
import { useUser } from '../../../apiClient/ApiContext';
import { getPaginatedResponse } from '../../../apiClient/utils';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import { SubmitButton } from '../../../components/buttons';
import ModalShell from '../../../components/modals/ModalShell';
import Spinner from '../../../components/Spinner';
import { PageTitle } from '../../../components/texts';
import Wrapper from '../../../components/wrapper';
import Container from '../../../containers';
import {
  ApplicationData,
  BranchofficeData,
  CompanyData,
  DocumentTypeData,
  DocumentTypeEnum,
  hasTableProp,
  isDocumentTypeData,
  isPerkData,
  ManShiftData,
  PerkData,
  PerkGroup,
  PerkId,
  PersonData,
  ShiftData,
} from '../../../types/TymbeApi';
import { joinDateTime } from '../../../utils';
import { Roles } from '../../../utils/enums';
import { checkIsAdultShift, getRequirements, isMedicalExaminationShift } from '../../../utils/shift';

type RouteParams = {
  shiftId: string;
};

const mapToShiftData = (
  values: Partial<ShiftFormValues>,
  company: CompanyData,
) => {
  let start_time;
  let end_time;
  if (values.start_time && values.end_time) {
    start_time = moment(values.start_time);
    end_time = moment(values.end_time);

    if (end_time <= start_time) {
      end_time.add(1, 'day');
    }
  }

  let documentTypes: DocumentTypeData[] = [];
  let perks: PerkData[] = [];
  let utility: UtilityData[] = [];

  let quantity = values.quantity || 0;
  const invitation_list = values.invitation_list || [];

  if (invitation_list.length > quantity) {
    quantity = invitation_list.length;
  }

  const manShiftCount = Number.isNaN(Number(quantity)) ? 0 : Number(quantity);
  const newDocTypes = values.requirements?.filter(isDocumentTypeData) || [];
  const newPerks = values.requirements?.filter(isPerkData) || [];

  documentTypes = [
    ...(values.shift_template?.documentType || []),
    ...newDocTypes,
  ];
  perks = [...(values.shift_template?.perk || []), ...newPerks];
  utility = [...(values.utility || [])];

  const branchoffice = {
    ...(values.department || values.branchoffice || ({} as BranchofficeData)),
  };
  branchoffice.parent = values.branchoffice;

  return {
    company: values.company || company,
    company_id: values.company?.id || company?.id,
    shift_template: values.shift_template,
    shift_template_id: values.shift_template?.id,
    perk: perks,
    utility,
    documentType: documentTypes,
    job_classification_ids: values.shift_template?.job_classification_ids,
    billing_rate: Number(values.shift_template?.billing_rate) || 0,
    margin: Number(values.shift_template?.margin) || 0,
    branchoffice,
    branchoffice_id: branchoffice?.id,
    position_id: values.shift_template?.position_id,
    contact_person: values.contact,
    name: values.shift_template?.name || values.shift_template?.template_name,
    manShift: new Array(manShiftCount).fill({}),
    payment_base: Number(values.shift_template?.payment_base),
    credits_min: Number(values.shift_template?.credits_min),
    credits: Number(values.shift_template?.credits),
    invitation_credits: Number(values.shift_template?.invitation_credits),
    start_time: start_time ? start_time.toISOString() : undefined,
    end_time: end_time ? end_time.toISOString() : undefined,
    contest_start: moment().toISOString(),
    contest_end: moment(start_time).subtract(1, 'hour').toISOString(),
    description: values.shift_template?.description,
    instruction: values.shift_template?.instruction,
    instruction_newcomers: values.shift_template?.instruction_newcomers,
    pay_supplement: values.shift_template?.pay_supplement,
    expected_hpp_weekly_hours: values.shift_template?.expected_hpp_weekly_hours,
    maximum_hpp_trial_period: values.shift_template?.maximum_hpp_trial_period,
    labels: values.shift_template?.labels,
  };
};

const adultPerk = { id: PerkId.ADULT, group: PerkGroup.AGE } as PerkData;

const Shift = () => {
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [shift, setShift] = useState<Partial<ShiftData>>({});
  const [dates, setDates] = useState<string[] | undefined>([]);
  const [invitations, setInvitations] = useState<PersonData[] | undefined>([]);
  const [extraRequirements, setExtraRequirements] = useState<(PerkData | DocumentTypeData)[]
  >([]);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorModalMessages, setErrorModalMessages] = useState<string[] | undefined>(undefined);

  const history = useNavigate();
  const { shiftId } = useParams<RouteParams>();
  const user = useUser();

  const formApiRef = useRef<FormApi<ShiftFormValues>>();

  const { data: medicalExaminationDocuments } = useQuery(
    ['FetchEntryMedicalExamination'],
    async () => feathersClient.service('document-type').find(
      { query: { type: DocumentTypeEnum.MEDICAL_EXAMINATION } },
    ),
  );
  const medicalExaminationDocumentType = medicalExaminationDocuments?.data[0];

  const selectedPerks = formApiRef.current?.getValue('requirements')?.map((prk) => prk.id);
  const enableTymberCountFetch = [
    PerkId.COMPANY_SENIOR, PerkId.BRANCHOFFICE_SENIOR,
    PerkId.POSITION_SENIOR, PerkId.ADULT,
    PerkId.WOMAN_ONLY, PerkId.MAN_ONLY].some(
    (perk) => selectedPerks?.includes(perk),
  );

  const { data: tymberCount, isLoading } = useQuery(
    ['tymberCount', formApiRef.current?.getValue('requirements')],
    async () => {
      const values = formApiRef.current?.getFormState().values;
      const requirements = formApiRef.current?.getValue('requirements')?.map((perk) => perk.id);

      const modify = {
        ...(requirements?.includes(PerkId.COMPANY_SENIOR) && { workedForCompany: [values?.company?.id] }),
        ...(requirements?.includes(PerkId.BRANCHOFFICE_SENIOR) && { workedForBranch: [values?.department?.id] }),
        ...(requirements?.includes(PerkId.POSITION_SENIOR) && { workedAtPosition: [values?.shift_template?.id] }),
        ...(requirements?.includes(PerkId.ADULT) && { adult: true }),
        ...(requirements?.includes(PerkId.WOMAN_ONLY) && { womanOnly: true }),
        ...(requirements?.includes(PerkId.MAN_ONLY) && { manOnly: true }),
      };

      const res = await feathersClient.service('person-perks').find(
        {
          query: {
            $modify: { ...modify },
            $limit: 0,
          },
        },
      );
      return getPaginatedResponse(res).total;
    },
    { enabled: enableTymberCountFetch },
  );

  const onFormChange = useCallback(
    async (formState: FormState<ShiftFormValues>) => {
      const isAdultShift = formState.values.shift_template?.perk?.some(
        (x) => x.id === 'adult',
      );
      const newRequirements = formState.values.requirements || [];

      const checkAdultShift = checkIsAdultShift(
        formState.values.start_time,
        formState.values.end_time,
      );

      // Check if adult tag was already selected (manually) & the shift is
      // identified as `adult`, remove the 18+ requirement from requirements
      // dropdown input (since it should be now forced --mandatory, not optional).
      if ((isAdultShift || checkAdultShift) && newRequirements.length) {
        const req = newRequirements.filter((x) =>
          (isPerkData(x) ? x.group !== PerkGroup.AGE : true));
        if (req.length !== newRequirements.length) {
          formApiRef.current?.setValue('requirements', req);
          return;
        }
      }

      const mappedShift = mapToShiftData(
        formState.values,
        user.person.company?.[0],
      );
      // Check if shift is for adults only based on legislatives (time, duration)
      // and show the `18+` tag
      if (!isAdultShift && checkAdultShift) {
        mappedShift.perk = [adultPerk, ...mappedShift.perk];
      }
      // Check if shift is for adults only (based on current settings)
      // and hide `adult` perk from requirements dropdown input in form
      if (
        !isAdultShift
        && checkAdultShift
        && !extraRequirements.includes(adultPerk)
      ) {
        setExtraRequirements([adultPerk]);
      } else if (
        (isAdultShift || !checkAdultShift)
        && extraRequirements.includes(adultPerk)
      ) {
        setExtraRequirements(extraRequirements.filter((x) => x !== adultPerk));
      }

      if (isMedicalExaminationShift(formState.values.start_time, formState.values.end_time)
        && !mappedShift.documentType.some((dt) => dt.type === DocumentTypeEnum.MEDICAL_EXAMINATION)
        && mappedShift.company_id) {
        const companyWithContractor: CompanyData = await feathersClient.service('company').get(
          mappedShift.company_id,
          { query: { $eager: '[contractor]' } },
        );

        // DISCLAIMER: This is due to TD-2407. In first stage we need to test this functionality on our (113) company.
        // Therefore it's fixed for the 113 constant.
        // It should be expanded later to all DPC contracts? (or the SERVICE fullfilment type companies?)
        if (companyWithContractor.contractor?.some((contractor) => contractor.id === 113)) {
          mappedShift.documentType = [...mappedShift.documentType, medicalExaminationDocumentType];

          if (!extraRequirements.filter(isDocumentTypeData)
            .some((r) => r.type === DocumentTypeEnum.MEDICAL_EXAMINATION)) {
            setExtraRequirements([...extraRequirements, medicalExaminationDocumentType]);
          }
        }
      }
      if (
        !isMedicalExaminationShift(formState.values.start_time, formState.values.end_time)
        && extraRequirements.includes(medicalExaminationDocumentType)
      ) {
        setExtraRequirements(extraRequirements.filter(
          (r) => r !== medicalExaminationDocumentType,
        ));
      }

      const quantity = formState.values.quantity || 0;
      const invitation_list = formState.values.invitation_list || [];
      if (invitation_list.length > quantity) {
        formApiRef.current?.setValue('quantity', invitation_list.length);
      }

      setShift(mappedShift);
      setDates(formState.values.start_date);
      setInvitations(formState.values.invitation_list);
    },
    [extraRequirements, user.person.company, medicalExaminationDocuments],
  );

  const onSubmit = useCallback(
    async (data: FormState<ShiftFormValues>): Promise<void> => {
      setFormDisabled(true);
      const userCompany = data.values.company || user.person.company?.[0];
      if (userCompany.is_readonly && !user.hasRoles([Roles.SUPER_ADMIN])) {
        ErrorAlert('Nelze přidat objednávku do organizace pouze pro čtení');
        setFormDisabled(false);
        return;
      }

      const { branchoffice, company, shift_template, ...mappedShift } =
        mapToShiftData(data.values, userCompany);
      if (!Array.isArray(data.values.start_date)) return;
      try {
        const shifts = data.values.start_date?.map((date: string): Partial<ShiftData> => {
          const start_time = joinDateTime(date, moment(mappedShift.start_time));
          const end_time = joinDateTime(date, moment(mappedShift.end_time));

          if (end_time <= start_time) {
            end_time.add(1, 'day');
          }

          return {
            ...mappedShift,
            perk: mappedShift.perk.concat(extraRequirements.filter(isPerkData)),
            documentType: mappedShift.documentType.concat(
              extraRequirements.filter(isDocumentTypeData),
            ).map((doc) => ({
              id: doc.id,
            }) as DocumentTypeData),
            utility: mappedShift.utility.map((util) => ({
              id: util.id,
            })) as UtilityData[],
            start_time: start_time.toISOString(),
            end_time: end_time.toISOString(),
            contest_start: moment().toISOString(),
            contest_end: start_time.subtract(1, 'hour').toISOString(),
          };
        });

        // Check if invitations can be send before any changes are written
        if (
          data.values.invitation_list
          && data.values.invitation_list.length !== 0
        ) {
          const { invitation_list } = data.values;
          const invites = invitation_list.map((invitation) => ({
            person_id: invitation.id,
            person_full_name: `${invitation.first_name} ${invitation.last_name}`,
            shifts,
          }));

          const inviteCheckResp = await feathersClient
            .service('invite-check')
            .create(invites);
          if (!inviteCheckResp.canInvite && !user.hasRoles([Roles.SUPER_ADMIN])) {
            setErrorModalMessages(inviteCheckResp.messages);
            setShowErrorModal(true);
            setFormDisabled(false);
            return;
          }
        }

        // Create shifts
        const newShifts: Partial<ShiftData>[] = await feathersClient
          .service('shift')
          .create(shifts, { query: { $eager: 'manShift' } });

        // Check if invitations are desired
        if (
          data.values.invitation_list
          && data.values.invitation_list.length !== 0
        ) {
          const { invitation_list } = data.values;
          // iterate over {invitation_list} * number of {shifts} created
          const applications: Partial<ApplicationData>[] = newShifts
            .map((newShift) =>
              invitation_list.map((invitation, key) => ({
                credits: Number(newShift.invitation_credits),
                payment_base: Number(newShift.payment_base),
                invitation: true,
                state: null,
                person_id: invitation.id,
                man_shift_id: newShift.manShift?.[key].id,
                manShiftReverse: {
                  id: newShift.manShift?.[key].id,
                  shift_id: newShift.id,
                } as ManShiftData,
                employer_id: newShift.company_id,
              })))
            .flat();

          await feathersClient.service('application').create(applications);
        }
      } catch (err: unknown) {
        setFormDisabled(false);
        if (!(err instanceof FeathersError) || !hasTableProp(err.data)) {
          ErrorAlert('Nastala chyba');
          history('/shift');
          throw err;
        }
        switch (err.data?.table || err.message) {
          case 'application': {
            ErrorAlert(
              'Směny byly úspěšně vytvořeny, nebylo možné vytvořit pozvánky',
            );
            // TODO: Redirect to orders table with filtered (corrupted / failed) orders
            history('/shift');
            break;
          }

          case 'shift':
            ErrorAlert('Nepovedlo se vytvořit směny');
            history('/shift');
            break;

          case 'ERROR/AUTH: Shift must be created in future':
            ErrorAlert('Směna musí být vytvořena v budoucnosti');
            break;

          case 'Nelze překročit částku 4 000,- Kč za měsíc.':
            // eslint-disable-next-line no-case-declarations
            const errData = err.data as Record<string, unknown>;
            ErrorAlert(
              `Tymber ${errData.person_id} ${errData.person_full_name} by touto směnou překročil rozhodný příjem, avšak má zaplou kontrolu překročení, proto jej není možné pozvat.`,
            );
            break;

          default:
            ErrorAlert('Nastala chyba');
            history('/shift');
            break;
        }
        return;
      }

      setFormDisabled(false);
      SuccessAlert('Objednávka byla úspešně vytvořena');
      history('/shift');
    },
    [extraRequirements, history, user.person.company],
  );

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#ff0"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <PageTitle> Nová objednávka </PageTitle>
        <Card>
          <Form<ShiftFormValues>
            name="shift-form"
            onSubmit={onSubmit}
            onChange={onFormChange}
            formApiRef={formApiRef}
          >
            <div className={styles.root}>
              <div className={styles.row}>
                <div
                  className={`${styles.column} ${styles.min_width_500} ${styles.max_width_33pr}`}
                >
                  <ShiftForm
                    shiftId={shiftId}
                    companyId={user.person.company?.[0]?.id}
                    hideRequirements={getRequirements(
                      shift.shift_template,
                      user.role,
                    ).concat(extraRequirements)}
                  />
                  <Spinner show={isLoading} className="w-8 p-1 m-auto" />
                  {tymberCount !== undefined
                  && (
                    <div className="text-xs text-danger p-1">
                      Vaši objednávku vidí přesně {tymberCount} Tymberů.
                      Chcete více kandidátů? Zkuste odebrat některé filtry a oslovit tak více brigádníků!
                    </div>
                  )}
                  <SubmitButton
                    style={{ marginTop: '18px' }}
                    disabled={formDisabled}
                    buttontext="Uložit"
                  />
                </div>
                <ShiftRecapitulation
                  values={shift}
                  dates={dates}
                  invitations={invitations}
                />
              </div>
            </div>
          </Form>
        </Card>
      </Wrapper>
      <ModalShell showModal={showErrorModal} onClose={() => setShowErrorModal(false)}>
        <div className="flex flex-col gap-10 justify-between items-center">
          <h2> Tyto uživatele není možné pozvat </h2>
          <ul>
            {errorModalMessages?.map((msg) => <li key={msg}> {msg} </li>)}
          </ul>
          <div className="self-end">
            <button
              type="button"
              className="ty-button-secondary"
              onClick={() => setShowErrorModal(false)}
            >
              Zavřít
            </button>
          </div>
        </div>
      </ModalShell>
    </Container>
  );
};

export default Shift;
